import React, { useState } from 'react';
import validate from '../../../assets/images/validate.png';
import progress from '../../../assets/images/progress.png';
import waiting from '../../../assets/images/waiting.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './styles.scss';
import '../../../styles/_vars.scss';
import ProgressBar from '../../Questionaries/QuestionaryComponents/ProgressBar';

type AchievementsProps = {
  icon: string,
  number: number,
  status: string,
  statusColor: string,
  formations: {id: number, name: string, status: number, Formation: {id: number, name: string, category: string}}[],
  activeNav: boolean
}


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '22rem',
    borderRadius: '1rem',
  },
};

const customStylesQuestionsDesktop = {
    overlay: {
        zIndex: '1'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        borderRadius: '1rem',
        backgroundColor: '#070C3A',
        zIndex: '2',
        color: '#fff',
        padding: '0'
    }
}

const customStylesQuestions = {
    overlay: {
        zIndex: '1'
    },
    content: {
        top: '12rem',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        borderRadius: '1rem',
        backgroundColor: '#070C3A',
        zIndex: '2',
        color: '#fff',
        padding: '0'
    }
}

Modal.setAppElement('body');

function Achievements({ icon, number, status, statusColor, formations, activeNav }: AchievementsProps) {

  if (icon === 'validate') {
    icon = validate
    statusColor = '#26A653'
  } else if (icon === 'progress') {
    icon = progress
    statusColor = '#F2AE2E'
  } else {
    icon = waiting
    statusColor = '#E70F47'
  }
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [questionsModalIsOpen, setQuestionsModalIsOpen] = useState(false);
  const [chevronUp, setChevronUp] = useState(false);
  const [formationTitleModal, setFormationTitleModal] = useState('');

  const toggleChevron: () => boolean | void = () => {
    setChevronUp(!chevronUp);
    setModalIsOpen(!modalIsOpen);
  }

  const toggleQuestionModal = () => {
    setQuestionsModalIsOpen(!questionsModalIsOpen);
    setModalIsOpen(!modalIsOpen);
  }

  return (
    <div className="Achievements" style={!chevronUp ? {maxHeight: '5rem'}: {maxHeight: 'none'}}>
      {!activeNav && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            modalIsOpen ? setModalIsOpen(false) : setModalIsOpen(true);
          }}
          style={customStyles}
        >
          <div className="Achievements-status" style={{color: `${statusColor}`}}>
          <img className="Achievements-icon" src={icon} alt= {`${icon} icône`} />
          <span className="Achievements-status--number">
            {number}
          </span>
          {status}
          <FontAwesomeIcon className='Achievements-modal--button' icon={faXmark} style={{color: "gray"}} onClick={toggleChevron} />
          </div>
          <ul className='Achievements-modal--block'>
            {formations.map(element => (
              <>
                <li key={`${element.Formation.id + 1}`} className='Achievements-modal--list' 
                  onClick={() => {
                    toggleQuestionModal();
                    setFormationTitleModal(element.Formation.name);
                  }}>
                  {element.Formation.name}
                </li>
              </>
            ))}
          </ul>
        </Modal>
      )}

      <Modal
        isOpen={questionsModalIsOpen}
        onRequestClose={() => {
          questionsModalIsOpen ? setQuestionsModalIsOpen(false) : setQuestionsModalIsOpen(true);
        }}
        style={!activeNav ? customStylesQuestions : customStylesQuestionsDesktop}
      >
        <div className="Achievements-modal--questions">
          <FontAwesomeIcon className='Achievements-modal--button' icon={faXmark} style={{color: "#fff", width: '0.8rem', position: 'relative', left: '98%', top: '1rem'}}  />
          
          <h3 className='Achievements-modal--questions-title' style={{marginLeft: '-1rem'}}>Questionnaire {formationTitleModal}</h3>
          <ProgressBar />
        </div>

      </Modal>

      <div className="Achievements-status" style={{color: `${statusColor}`}} onClick={toggleChevron}>
        <img className="Achievements-icon" src={icon} alt={`${icon} icône`} />
        <span className="Achievements-status--number">
          {number}
        </span>
        {status}
        {!chevronUp && activeNav  && (
          <FontAwesomeIcon className="Achievements-status--chevron" icon={faChevronDown} />
        )}
        {chevronUp && activeNav && (
          <FontAwesomeIcon className="Achievements-status--chevron" icon={faChevronUp} />
        )}
      </div>

      {chevronUp && activeNav && (
        <ul className='Achievements-formations'>
          {formations.map(element => (
            <>
              <li key={`${element.Formation.id}`} className='Achievements-formations--list'
                onClick={() => {
                  toggleQuestionModal();
                  setFormationTitleModal(element.Formation.name);
                }}>
                  {element.Formation.name}
              </li>
            </>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Achievements;
