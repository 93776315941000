import React, { useEffect, useState } from 'react';
import './styles.scss';
import './common01.scss';
import './btp01.scss';
import './hygiene01.scss';
import './industrie01.scss';
import './transport01.scss';
import './loader.scss';


function Scene({ question, imageAction, loader, init_sequence }) {  
  const [imagePlan01, setImagePlan01] = useState('');
  const [imagePlan02, setImagePlan02] = useState('');
  const [imagePlan03, setImagePlan03] = useState('');
  const [imagePlan04, setImagePlan04] = useState('');
  const [imagePlan05, setImagePlan05] = useState('');
  
  useEffect(() => {    
    if (question) {
      const splitPlanArray = question.animation_array[0].split(',');
      const plan01 = splitPlanArray[0].replace(' ', '');
      const plan02 = splitPlanArray[1] ? splitPlanArray[1].replace(' ', '') : '';
      const plan03 = splitPlanArray[2] ? splitPlanArray[2].replace(' ', '') : '';
      const plan04 = splitPlanArray[3] ? splitPlanArray[3].replace(' ', '') : '';      

      const imageActionArray = imageAction.split(',');

      const playScene = () => {
        if (plan01 === 'icon-Industrie08_Illus00Plan01') {
          setTimeout(() => {
            imageActionArray[0] && setImagePlan05(`https://${window.location.hostname}/api/images/${question.Formation.name}/${imageActionArray[0].replace(' ', '')}.png`);
          }, 0);
          setTimeout(() => {
            setImagePlan01('');
            setImagePlan02('');
            setImagePlan03('');
            setImagePlan04('');
            setImagePlan05('');
            imageActionArray[1] && setImagePlan02(`https://${window.location.hostname}/api/images/${question.Formation.name}/${imageActionArray[1].replace(' ', '')}.png`);
          }, 1000);
          setTimeout(() => {
            imageActionArray[2] && setImagePlan01(`https://${window.location.hostname}/api/images/${question.Formation.name}/${imageActionArray[2].replace(' ', '')}.png`);
          }, 2000);
        }
        else {
          setTimeout(() => {
            imageActionArray[0] && setImagePlan01(`https://${window.location.hostname}/api/images/${question.Formation.name}/${imageActionArray[0].replace(' ', '')}.png`);
          }, 0);
          setTimeout(() => {
            if (imageActionArray[1] === '') {
            setImagePlan02('');
            setImagePlan03('');
            setImagePlan04('');
            }
            imageActionArray[1] && setImagePlan01(`https://${window.location.hostname}/api/images/${question.Formation.name}/${imageActionArray[1].replace(' ', '')}.png`);
          }, 1000);
          setTimeout(() => {
              imageActionArray[2] && setImagePlan01(`https://${window.location.hostname}/api/images/${question.Formation.name}/${imageActionArray[2].replace(' ', '')}.png`);
            }, 2000);
            setTimeout(() => {
              imageActionArray[3] && setImagePlan01(`https://${window.location.hostname}/api/images/${question.Formation.name}/${imageActionArray[3].replace(' ', '')}.png`);
            }, 3000);
        }}
        if (imageAction !== '') {
          setTimeout(() => {
            playScene();
        }, 1000);
      }
      
    
      setImagePlan01(`https://${window.location.hostname}/api/images/${question.Formation.name}/${plan01}.png`);
      setImagePlan02(`https://${window.location.hostname}/api/images/${question.Formation.name}/${plan02}.png`)
      setImagePlan03(`https://${window.location.hostname}/api/images/${question.Formation.name}/${plan03}.png`);
      setImagePlan04(`https://${window.location.hostname}/api/images/${question.Formation.name}/${plan04}.png`);

    }
  }, [question, imageAction, init_sequence])


    


  return (
    <div className={!loader ? 'Scene' : 'Scene-loading'}>
      {loader && (
        <div className="lds-dual-ring" />
      )}
      {!loader && (
        <div className={question && question.fond !== "" ? `${question.fond}` : "Scene-no--fonds"}>
          {question && (
            <>
              {imagePlan01 !== '' && imagePlan01 !== `https://${window.location.hostname}/api/images/${question.Formation.name}/.png` && (
                <>
                  {init_sequence && (
                    <img className='Scene-plan' src={`https://${window.location.hostname}/api/images/${question.Formation.name}/${init_sequence}.png`} alt={init_sequence} />
                  )}
                  {!init_sequence && (
                    <img className='Scene-plan' src={imagePlan01} alt={imagePlan01} />
                  )}
                </>
              )}
              {imagePlan02 !== '' && imagePlan02 !== `https://${window.location.hostname}/api/images/${question.Formation.name}/.png` && (
                  <img className='Scene-image' src={imagePlan02} alt={imagePlan02} />
              )}
              {imagePlan03 !== '' && imagePlan03 !== `https://${window.location.hostname}/api/images/${question.Formation.name}/.png` && (
                  <img className='Scene-image03' src={imagePlan03} alt={imagePlan03} />
              )}
              {imagePlan04 !== '' && imagePlan04 !== `https://${window.location.hostname}/api/images/${question.Formation.name}/.png` && (
                  <img className='Scene-image03' src={imagePlan04} alt={imagePlan04} />
              )}
              {imagePlan05 !== '' && imagePlan05 !== `https://${window.location.hostname}/api/images/${question.Formation.name}/.png` && (
                  <img className='Scene-image03' src={imagePlan05} alt={imagePlan05} />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Scene;
