import React, { SetStateAction, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import missile from '../../../assets/images/missile.png';
import archive from '../../../assets/images/download-archive.png';
import './styles.scss';
import UserLogs from './UserLogs/UserLogs';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

type NavBarProps = {
  username: string | null,
  toggleNavbar: () => boolean | void,
  activeNav: boolean,
  setActiveNav: React.Dispatch<SetStateAction<boolean>>,
  language: string,
  setLanguage: React.Dispatch<React.SetStateAction<string>>
}

function NavBar({ username, language, setLanguage, toggleNavbar, activeNav, setActiveNav }: NavBarProps) {

  // Gestion dynamique de la NavBar en fonction des tailles d'écrans.
  const [screenWidth, getScreenWidth] = useState({dynamicWidth: window.innerWidth});

  const setDimension = () => {
    getScreenWidth({dynamicWidth: window.innerWidth});
  }
  
  useEffect(() => {
    screenWidth.dynamicWidth > 900 ? setActiveNav(true) : setActiveNav(false);
    window.addEventListener('resize', setDimension);
    return(() => {
      window.removeEventListener('resize', setDimension)
    })
  }, [screenWidth, setActiveNav])

  return (
    <div className={!activeNav ? 'NavBar' : 'NavBar-open'}>
        {!activeNav && (
          <button onClick={toggleNavbar} className="NavBar-button">
              <FontAwesomeIcon className='NavBar-button-font' icon={faBars} />
          </button>
        )}
        {activeNav  && (
          <div>
            <button onClick={toggleNavbar} className="NavBar-button">
              <FontAwesomeIcon className='NavBar-button-font' icon={faXmark} />
            </button>
              <ul className='NavMenu'>
                <NavLink to="/">
                  <li className='NavMenu-element'>
                    <img src={missile} alt="home" style={{width: '24px', marginRight: '0.5rem'}}/>
                    <Trans>Partials.Navbar.Home</Trans>
                  </li>
                </NavLink>
                <li className='NavMenu-element'>
                  <img src={archive} alt="supplay" style={{width: '24px', marginRight: '0.5rem'}}/>
                  <Trans>Partials.Navbar.About</Trans>
                </li>
              </ul>
              <UserLogs language={language} setLanguage={setLanguage} username={username} />
          </div>
        )}
    </div>
  );
}

export default NavBar;
