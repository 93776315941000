import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AchievementsQuestions from './Achievements/AchievementsQuestions';
import './styles.scss';

type MonitoringProps = {
  title: string,
  activeNav: boolean,
  // formations: {id: number, name: string, status: string}[]
  formations: any
}

function Monitoring({ activeNav, title, formations }: MonitoringProps) {
  
  const { t } = useTranslation();
  
  const [validatedFormations, setValidatedFormations] = useState([]);
  const [progressedFormations, setProgressedFormations] = useState([]);
  const [waitedFormations, setwaitedFormations] = useState([]);

  
  useEffect(() => {
    const waiting = [];
    const validate = [];
    const progress = [];
    const statusFormation = () => {
      formations.forEach((element) => {
        if (element) {
          if (element.status === 'pending') {
            waiting.push(element);
            setwaitedFormations(waiting);
          }
          else if (element.status === 'waiting') {
            progress.push(element);
            setProgressedFormations(progress);
          }
          else if (element.status === 'success') {
            validate.push(element);
            setValidatedFormations(validate);
          }
        }
        
      });
    }
    statusFormation();
  }, [formations]);
  
  
  return (
    <div className={!activeNav ? "Monitoring" : "Monitoring--blur"}>
      <h2 className="Monitoring-title">
        { title } :
      </h2>
      <div className="Monitoring-achievements">
        <AchievementsQuestions icon={'waiting'} number={waitedFormations.length} status={t('Monitoring.Progress.Waiting')} statusColor={'waiting'} formations={waitedFormations} activeNav={activeNav} />
        <AchievementsQuestions icon={'progress'} number={progressedFormations.length} status={t('Monitoring.Progress.InProgress')} statusColor={'progress'} formations={progressedFormations} activeNav={activeNav} />
        <AchievementsQuestions icon={'validate'} number={validatedFormations.length} status={t('Monitoring.Progress.Validate')} statusColor={'validate'} formations={validatedFormations} activeNav={activeNav} />
      </div>
      
    </div>
  );
}

export default Monitoring;
