import React, { useEffect, useState } from 'react';
import Header from '../Partials/Header';
import MonitoringFormations from '../Monitoring/MonitoringFormations';
import MonitoringQuestions from '../Monitoring/MonitoringQuestions';
import characters from '../../assets/images/homepage-characters.png';
import './styles.scss';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import axios from 'axios';

type HomePageProps = {
  username: string | null,
  activeNav: boolean,
  language: string,
  setActiveNav: React.Dispatch<React.SetStateAction<boolean>>,
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  toggleNavbar: () => boolean | void
}

function HomePage({ username, activeNav, setActiveNav, language, setLanguage, toggleNavbar }: HomePageProps) {  

  const [formations, setFormations] = useState(Array<{id: number, name: string, status: number}>);

  const userId = localStorage.getItem('userid');
  
  useEffect(() => {
    // Get the formations and their status code
    const Formations = async () => {
      await axios.get(`https://${window.location.hostname}/api/userFormations/${userId}}`)
      .then(response => {
        setFormations(response.data)
      }).catch(error => {
        if (error.response.status === 404) {
          throw new Error('no user formations found');
        }
      })
    }
  Formations();
}, [userId])

  return (
    <div className="HomePage">
        <Header language={language} setLanguage={setLanguage} username={username} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar}/>
        
        <main className={!activeNav ? 'HomePage-main' : 'HomePage-main--blur'} onClick={() => {
          if (activeNav && (window.innerWidth < 900)) {
            toggleNavbar()
          }
        }}>
          <section className={!activeNav ? 'HomePage-welcome' : 'HomePage-welcome--blur'}>
            <img className="HomePage-welcome--image" src={characters} alt="Dummy et copine de Dummy" />
            <p className="HomePage-welcome--text">
              <Trans>Home.WelcomeText</Trans>
            </p>
            <p className="HomePage-welcome--text--soon">
              <Trans>Home.Soon</Trans> !
            </p>
            
          </section>
          <article className="HomePage-formations">
            <MonitoringFormations activeNav={activeNav} title={t('Monitoring.Title.Formations')} formations={formations}/>
          </article>
          <article className="HomePage-questionaries">
            <MonitoringQuestions activeNav={activeNav} title={t('Monitoring.Title.Questionaries')} formations={formations}/>
          </article>
          
        </main>
    </div>
  );
}

export default HomePage;
