import React, { useEffect, useState } from 'react';
import validate from '../../../assets/images/validate.png';
import progress from '../../../assets/images/progress.png';
import waiting from '../../../assets/images/waiting.png';
import plain_curve from '../../../assets/images/plain_curve.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './styles.scss';
import '../../../styles/_vars.scss';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

type AchievementsProps = {
  icon: string,
  number: number,
  status: string,
  statusColor: string,
  formations: {id: number, name: string, status: number, Formation: {id: number, name: string, category: string}}[],
  activeNav: boolean
}


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '0.5rem',
    padding: '0.5rem',
  },
};

const customStylesDesktop = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginLeft: '8rem',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '25rem',
    borderRadius: '1rem',
    padding: '1rem'
  },
};

Modal.setAppElement('body');

function Achievements({ icon, number, status, statusColor, formations, activeNav }: AchievementsProps) {

  if (icon === 'validate') {
    icon = validate
    statusColor = '#26A653'
  } else if (icon === 'progress') {
    icon = progress
    statusColor = '#F2AE2E'
  } else {
    icon = waiting
    statusColor = '#E70F47'
  }
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [questionsModalIsOpen, setQuestionsModalIsOpen] = useState(false);
  const [chevronUp, setChevronUp] = useState(false);
  const [questions, setQuestions] = useState(Array<{id: number, title: string, subtitle: string, status: string, number: number, formationId: number}>);
  const [formationTitleModal, setFormationTitleModal] = useState('');
  const [formation, setFormation] = useState();
  const [isQuestionsPending, setIsQuestionsPending] = useState(true);

  const toggleChevron: () => boolean | void = () => {
    setChevronUp(!chevronUp);
    setModalIsOpen(!modalIsOpen);
  }

  const toggleQuestionModal = () => {
    setQuestionsModalIsOpen(!questionsModalIsOpen);
    setModalIsOpen(!modalIsOpen);
  }


  const getQuestions = (userFormationId, formationId) => {    
    axios.get(`https://${window.location.hostname}/api/userQuestions/${userFormationId}/${formationId}}`)
    .then((response) => {
      setQuestions(response.data);
    })
    .catch((error) => {
      if (error.status === 404) {
        throw new Error('404');
      }
    })
  }

  const next = questions.find(element => element.status === 'pending');
  const nextQuestion = next?.Question.number;
  
  useEffect(() => {
    const pending = []
    questions.forEach(element => {
      if (element.status === 'pending') {
        pending.push(element);        
      }
      if (pending.length > 0) {
        setIsQuestionsPending(false);
      } else {
        setIsQuestionsPending(true);
      }
    })
    
  }, [questions, nextQuestion])


  return (
    <div className="Achievements" style={!chevronUp ? {maxHeight: '5rem'}: {maxHeight: 'none'}}>
      {!activeNav && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            modalIsOpen ? setModalIsOpen(false) : setModalIsOpen(true);
          }}
          style={customStyles}
        >
          <div className="Achievements-status" style={{color: `${statusColor}`}}>
          <img className="Achievements-icon" src={icon} alt= {`${icon} icône`} />
          <span className="Achievements-status--number">
            {number}
          </span>
          {status}
          <FontAwesomeIcon className='Achievements-modal--button' icon={faXmark} style={{color: "gray"}} onClick={toggleChevron} />
          </div>
          <ul className='Achievements-modal--block'>
            {formations.map(element => (
              <>
                <li key={`${element.Formation.id}`} className='Achievements-modal--list' 
                  onClick={() => {
                    toggleQuestionModal();
                    getQuestions(element.id, element.formationId);
                    setFormationTitleModal(element.Formation.name);
                    setFormation(element);
                  }}>
                  {element.Formation.name}
                </li>
              </>
            ))}
          </ul>
        </Modal>
      )}

      <Modal
        isOpen={questionsModalIsOpen}
        onRequestClose={() => {
          questionsModalIsOpen ? setQuestionsModalIsOpen(false) : setQuestionsModalIsOpen(true);
        }}
        style={!activeNav ? customStyles: customStylesDesktop}
      >
        <div className="Achievements-modal--questions">
          <FontAwesomeIcon className='Achievements-modal--button' icon={faXmark} style={{color: "#323BA2", width: '0.8rem', position: 'relative', left: '95%', top: '1rem'}} onClick={toggleQuestionModal} />
          
          <h3 className='Achievements-modal--questions-title'>{formationTitleModal}</h3>

          <ul className="Achievements-modal--questions-block">
            {questions.map(element => 
              element.status !== 'pending' || element.Question.number === nextQuestion ? (
                <>
                    <NavLink to={`/question/${element.id}`} state={{questions: questions, question: element.Question, formation: formation, isQuestionsPending: isQuestionsPending}}>
                        <li key={`${element.id}`} className="Achievements-modal--questions-list"
                        style={element.status === 'waiting' ? 
                          {background: 'linear-gradient(28.47deg, #E70F47 13.8%, #F59FB5 86.34%)'} : 
                          element.status === 'success' ? 
                          {background: 'linear-gradient(28.47deg, #26A653 13.8%, #7ABC91 86.34%)'} : 
                          {background: 'linear-gradient(28.47deg, #C0C2D0 13.8%, #DFE1EE 86.34%)'}
                        }>
                          {element.Question.number}
                        </li>
                      </NavLink>
                    </>
              ) : (
                <>
                  <li className="Achievements-modal--questions-list"
                    style={
                      {background: 'linear-gradient(28.47deg, #C0C2D0 13.8%, #DFE1EE 86.34%)'}
                    }>
                    {element.Question.number}
                  </li>
                </>
              )
            )}
          </ul>

          {isQuestionsPending === true && (
            <NavLink to='/formation-results' style={{alignSelf: 'center', width: '100%'}} state={{formation: formation, questions: questions}}>
              <button className='Achievements-modal--questions-button'>
                Ou voir le résultat
              </button>
            </NavLink>
          )}
        </div>

      </Modal>

      <div className="Achievements-status" style={{color: `${statusColor}`}} onClick={toggleChevron}>
        <img className="Achievements-icon" src={icon} alt={`${icon} icône`} />
        <span className="Achievements-status--number">
          {number}
        </span>
        {status}
        {!chevronUp && activeNav  && (
          <FontAwesomeIcon className="Achievements-status--chevron" icon={faChevronDown} />
        )}
        {chevronUp && activeNav && (
          <FontAwesomeIcon className="Achievements-status--chevron" icon={faChevronUp} />
        )}
      </div>

      {chevronUp && activeNav && (
        <ul className='Achievements-formations'>
          {formations.map(element => (
            <>
              <li key={`${element.Formation.id}`} className='Achievements-formations--list'
                onClick={() => {
                  toggleQuestionModal();
                  getQuestions(element.id, element.formationId);
                  setFormationTitleModal(element.Formation.name);
                  setFormation(element);
                }}>
                  {element.Formation.name}
              </li>
            </>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Achievements;
