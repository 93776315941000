import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnswerProvider, AnswerResponseProvider } from '../../../context/AnswerContext';
import { FormationProvider } from '../../../context/FormationContext';
import { SceneProvider } from '../../../context/SceneContext';
import Header from '../../Partials/Header';
import Answer from '../FormationComponents/Answer/Answer';
import Question from '../FormationComponents/Question/Question';
import Scene from '../FormationComponents/Scene/Scene';
import './styles.scss';

type FormationProps = {
  username: string | null,
  language: string,
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  toggleNavbar: () => boolean | void,
  activeNav: boolean,
  setActiveNav: React.Dispatch<React.SetStateAction<boolean>>,
  setLoader: React.Dispatch<React.SetStateAction<boolean>>
}


function FormationPage({ username, language, setLanguage, toggleNavbar, activeNav, setActiveNav }: FormationProps) {
  
  const location = useLocation();
  const [questionToDisplay, setQuestionToDisplay] = useState();
  const [questions, setQuestions] = useState();
  const [userFormation, setUserFormation] = useState();
  const [isResponseSuccess, setIsResponseSuccess] = useState();
  const [showLastNextButton, setShowLastNextButton] = useState(false);

  const [imageAction, setImageAction] = useState('');
  const [init_sequence, setInit_sequence] = useState('');
  const [loader, setLoader] = useState(false);

  
  
  useEffect(() => {
    setShowLastNextButton(location.state.isQuestionPending);
    setUserFormation(location.state.formation)
    setQuestions(location.state.questions);
    const getQuestionToDisplay = () => {
      axios.get(`https://${window.location.hostname}/api/question/${location.state.question.id}`)
      .then((response) => {
        setQuestionToDisplay(response.data);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          throw new Error('no question found');
        }
      })
    }
    getQuestionToDisplay()  
    
  }, [location])


  return (
    <div className="FormationPage" style={{overflow: 'hidden'}}>
        <Header language={language} setLanguage={setLanguage} username={username} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar} />
        <main className={!activeNav ? 'HomePage-main' : 'HomePage-main--blur'} onClick={() => {
          if (activeNav && (window.innerWidth < 900)) {
            toggleNavbar();
          }
        }}>
          <div className="Formation-sections">
              <FormationProvider>
                <AnswerProvider>
                  <AnswerResponseProvider>
                    <SceneProvider>
                      <section className='FormationPage-Question'>
                        {location && (
                          <Question activeNav={activeNav} question={questionToDisplay} questions={questions} isResponseSuccess={isResponseSuccess}  />
                          )
                        }
                      </section>
                      <section className={!activeNav ? 'FormationPage-Answer' : 'FormationPage-Answer--blur'}>
                        <Scene question={questionToDisplay} imageAction={imageAction} loader={loader} init_sequence={init_sequence}/>
                        {questionToDisplay && (
                          <Answer activeNav={activeNav} question={questionToDisplay} questions={questions}  userFormation={userFormation} setIsResponseSuccess={setIsResponseSuccess} showLastNextButton={showLastNextButton} setImageAction={setImageAction} setLoader={setLoader} setInit_sequence={setInit_sequence}/>
                        )  
                        }
                      </section>
                    </SceneProvider>
                  </AnswerResponseProvider>
                </AnswerProvider>
              </FormationProvider>

          </div>
        </main>
    </div>
  );
}

export default FormationPage;
