import React, { useEffect } from 'react';
import Header from '../../Partials/Header';
import characters from '../../../assets/images/homepage-characters.png';
import reloadIcon from '../../../assets/images/reload.png';
import emailIcon from '../../../assets/images/email.png';
import downloadIcon from '../../../assets/images/download.png';
import './styles.scss';
import { Trans } from 'react-i18next';
import axios from 'axios';
import { NavLink, useLocation } from 'react-router-dom';

type FormationResultsProps = {
  username: string | null,
  activeNav: boolean,
  language: string,
  setActiveNav: React.Dispatch<React.SetStateAction<boolean>>,
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  toggleNavbar: () => boolean | void
}

function FormationResults({ username, activeNav, setActiveNav, language, setLanguage, toggleNavbar }: FormationResultsProps) {  

  const location = useLocation();
  const formation= location.state.formation;
  const questionsState = location.state.questions;
  
  const [questions, setQuestions] = React.useState(questionsState);

  const getQuestions = (userFormationId, formationId) => {    
    axios.get(`https://${window.location.hostname}/api/userQuestions/${userFormationId}/${formationId}}`)
    .then((response) => {
      setQuestions(response.data);
    })
    .catch((error) => {
      if (error.status === 404) {
        throw new Error('404');
      }
    })
  }

  useEffect(() => {
    getQuestions(questionsState[0].userFormationId, questionsState[0].Question.formationId);
  }, [location, questionsState])

  const numberOfGoodAnswers = questions.filter((question: any) => question.status === 'success').length;

  return (
    <div className="HomePage">
        <Header language={language} setLanguage={setLanguage} username={username} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar}/>
        
        <main className={!activeNav ? 'HomePage-main' : 'HomePage-main--blur'} onClick={() => {
          if (activeNav && (window.innerWidth < 900)) {
            toggleNavbar()
          }
        }}>
          
          <section className={!activeNav ? 'HomePage-welcome' : 'HomePage-welcome--blur'}>
            <img className="HomePage-welcome--image" src={characters} alt="Dummy et copine de Dummy" />
            {numberOfGoodAnswers === questions.length && (
              <>
                <p className="HomePage-welcome--text">
                  <Trans>FormationResults.Congrats.Intro</Trans>
                <br />
                  <Trans>FormationResults.Congrats.Phrase</Trans>
                </p>
                <div className="FormationResults-buttons">
                  <NavLink to={`/question/${questions[0].questionId}`} state={{questions: questions, question: questions[0].Question, formation: formation}}>
                    <button className="FormationResults-retry">
                      <img src={emailIcon} alt="reload" className="FormationResults-retry--icon" />
                        Me l'envoyer par mail
                    </button>
                  </NavLink>
                  <NavLink to={`/question/${questions[0].questionId}`} state={{questions: questions, question: questions[0].Question, formation: formation}}>
                    <button className="FormationResults-retry" style={(window.innerWidth > 900) ? {marginLeft: '2rem'} : {marginTop: '0', marginBottom: '0'}}>
                      <img src={downloadIcon} alt="reload" className="FormationResults-retry--icon" />
                        Télécharge mon diplôme
                    </button>
                  </NavLink>
                </div>
              </>
            )}
            {numberOfGoodAnswers !== questions.length && (
              <>
                <p className="HomePage-welcome--text">
                  <Trans>FormationResults.Failed.Intro</Trans>          
                </p>
                <p className="HomePage-welcome--text">
                  <Trans>FormationResults.Failed.Phrase</Trans>
                </p>
                  <NavLink to={`/question/${questions[0].questionId}`} state={{questions: questions, question: questions[0].Question, formation: formation}}>
                    <button className="FormationResults-retry">
                      <img src={reloadIcon} alt="reload" className="FormationResults-retry--icon" />
                        Refaire l'entièreté de la formation
                    </button>
                  </NavLink>
              </>
            )}
          </section>

          <section className={!activeNav ? "FormationResults" : "FormationResults--blur"}>
            <h3 className="FormationResults-title">
              Formation {formation.Formation.category}
            </h3>

            <h4 className="FormationResults-subtitle"
              style={numberOfGoodAnswers !== questions.length ? {color: '#E70F47', backgroundColor: '#FFE1E9', padding: '0.5rem', borderRadius: '0.5rem'} : {color: '#26A653', backgroundColor: '#DDFFE9', padding: '0.5rem', borderRadius: '0.5rem'}}
            >
              {numberOfGoodAnswers} bonnes réponses sur {questions.length}
            </h4>
            <ul className="FormationResults-block">
              {questions.map((question: any) => (
                <NavLink key={question.questionId} to={`/question/${question.questionId}`} state={{questions: questions, question: question.Question, formation: formation}}>
                  <li key={question.questionId} className="FormationResults-block--question"
                    style={question.status === 'waiting' ? {color: '#E70F47'} : {color: '#323BA2'}}
                  >
                    {question.Question.number}. {question.Question.intro_text_panel}
                  </li>
                </NavLink>
              ))}
              <div className='FormationResults-bar'></div>
            </ul>
          </section>

          
        </main>
    </div>
  );
}

export default FormationResults;
