import React, { createContext, useState } from "react";

interface SceneContextType {
    QuestionId: number,
    choice: string

}

export const SceneContext = createContext<SceneContextType | null>(null);
export const SceneProvider = props => {
    const [sceneContext, setSceneContext] = useState<SceneContextType>();

    return (
        <SceneContext.Provider value={[sceneContext, setSceneContext]}>
            {props.children}
        </SceneContext.Provider>
    )
}