import React, { useEffect } from 'react';
import './styles.scss';


function QuestionComponent({ title, question, setCount, supplayResponse, entrepriseResponse, missionResponse, bilanResponse }) {

  const [protectionDetails, setProtectionDetails] = React.useState('');
  const [accident_avoided_details, setAccident_avoided_details] = React.useState('');
  const [showSecurityWelome, setShowSecurityWelome] = React.useState(false);
  const [showVibrationMachine, setShowVibrationMachine] = React.useState(false);
  const [showAccidentAvoided, setShowAccidentAvoided] = React.useState(false);
  const [checkMissionDetails, setCheckMissionDetails] = React.useState(false);
  
  const [validationForm, setValidationForm] = React.useState(false);
  
  const handleSupplayForm = () => {
    const radios = document.querySelectorAll('input[type="radio"]');
    const radiosChecked = document.querySelectorAll('input[type="radio"]:checked');
    if (radios.length / 2 === radiosChecked.length) {
      setValidationForm(true);
    }
  }

  const handleEntrepriseForm = () => {
    const radios = document.querySelectorAll('input[type="radio"]');
    const radiosChecked = document.querySelectorAll('input[type="radio"]:checked');
    const showDetail: any = document.querySelector('input[name="security_welcome"]:checked');
    const selectedChecboxes = document.querySelectorAll('input[type="checkbox"]:checked');
    let detailsArray: Array<String> = []
    
    selectedChecboxes.forEach((checkbox: any) => {
      detailsArray.push(checkbox.value);
    });
    if (radios.length / 2 === radiosChecked.length) {
      if (showDetail?.value === 'yes') {
        if (detailsArray.includes('meeting') || detailsArray.includes('formation') || detailsArray.includes('hosting_book_supplay') || detailsArray.includes('hosting_book_entreprise')) {
          setValidationForm(true);
        } else {
          setValidationForm(false);
        }
      }
      if (showDetail?.value === 'no') {
        if ((detailsArray.includes('net') || detailsArray.includes('harness')) && (detailsArray.includes('security_shoes') || detailsArray.includes('gloves') || detailsArray.includes('helmet') || detailsArray.includes('baudrier') || detailsArray.includes('hearing_protection'))) {
          setValidationForm(true);
        } else {
          setValidationForm(false);
        }
      }    
    }
  }

  const handleMissionForm = () => {
    const radios = document.querySelectorAll('input[type="radio"]');
    const radiosChecked = document.querySelectorAll('input[type="radio"]:checked');
    const showDetail: any = document.querySelector('input[name="vibration_machine"]:checked');
    if (showDetail?.value === 'yes') {
      setCheckMissionDetails(true);
      if (radios.length / 2 === radiosChecked.length) {
        setValidationForm(true);       
      } else {
        setValidationForm(false);
      }
    }
    if (showDetail?.value === 'no') {
      setCheckMissionDetails(false);
      if ((radios.length / 2) - 1 === radiosChecked.length) {
        setValidationForm(true);       
      } else {
        setValidationForm(false);
      }
    }
  }
  
  const handleBilanForm = () => {
    const radiosChecked = document.querySelectorAll('input[type="radio"]:checked');
    const showDetail: any = document.querySelector('input[name="accident_avoided"]:checked');
    if (showDetail?.value === 'no') {
      setAccident_avoided_details('');
      if (radiosChecked.length === 4) {
        setValidationForm(true);       
      } else {
        setValidationForm(false);
      }
    }
    if (showDetail?.value === 'yes') {
      const inputText: any = document.querySelector('input[name="accident_avoided_details"]');
      if (inputText?.value && inputText?.value.length !== '') {
        setValidationForm(true);
      } else {
        setValidationForm(false);
      }
    }
      
  }

  const handlePreviousResponses = (responses: object) => {
    const response = Object.entries(responses);
    response.forEach(key => {
      const input = document.querySelector(`input[id="${key[0]} ${key[1]}"]`);
      if (input) {
        input.checked = true;
      }
    })
  }

  useEffect(() => {    
    
    switch (title) {
      case '1. Supplay':
        handlePreviousResponses(supplayResponse);
        break;
      case '2. Entreprise':
        handlePreviousResponses(entrepriseResponse);
        break;
      case '3. Mission':
        handlePreviousResponses(missionResponse);
        break;
      case '4. Bilan':
        handlePreviousResponses(bilanResponse);
        break;
    }
    
   
    validationForm ? setCount(true) : setCount(false);
  }, [validationForm, title, setCount, showSecurityWelome, supplayResponse, entrepriseResponse, missionResponse, bilanResponse, question.questionTag])
  

    
  
  
  return (
    <li className='SupplayForm-block--question'>
      {question.question}
      {question.subQuestion && (
        <ul className="SupplayForm-subquestions">
          {question.subQuestion.map(subquestion => (
            <li className='SupplayForm-subquestions--question'>
              {subquestion.question}
              <div className='SupplayForm-subquestions--answer'>
              {subquestion.answers.map(answer => (
                    <div className='SupplayForm-subquestions--answer-choice'>
                      <input
                        id={`${subquestion.questionTag} ${answer.value}`}
                        type={answer.type}
                        value={`${answer.value}`}
                        name={subquestion.questionTag}
                        defaultChecked={false}
                        onChange={(e) => {
                          switch (title) {
                            case '1. Supplay':
                              handleSupplayForm()
                              break;
                            case '2. Entreprise':
                              handleEntrepriseForm()
                              break;
                            case '3. Mission':
                              handleMissionForm()
                              break;
                            case '4. Bilan':
                              handleBilanForm()
                              break;
                          }
                        }}
                        />
                      <label htmlFor={`${subquestion.questionTag} ${answer.value}`}>{answer.label}</label>
                    </div>
                  ))}
                  </div>
            </li>
            ))}
        </ul>
      )}

      {question.answers && (
        <div className='SupplayForm-questions--answer'>
          {question.answers.map(answer => (
            <div 
              className={answer.type === 'radio' ? 'SupplayForm-questions--answer-choice' : 'SupplayForm-questions--answer-answer'}
              style={
                answer.type === 'text' && window.innerWidth < 900 ? {width: '100%'} :
                answer.type === 'text' && window.innerWidth >= 900 ? {width: '70%'} :
                {}}>
                {(answer.type === 'radio') && (
                  <input 
                    id={`${question.questionTag} ${answer.value}`}
                    type={answer.type}
                    value={`${answer.value}`}
                    name={question.questionTag}
                    defaultChecked={false}
                    onChange={(e) => {
                      switch (title) {
                        case '1. Supplay':
                          handleSupplayForm()
                          break;
                        case '2. Entreprise':
                          handleEntrepriseForm()
                          break;
                        case '3. Mission':
                          handleMissionForm()
                          break;
                        case '4. Bilan':
                          handleBilanForm()
                          break;
                      }
                      
                    }}
                    onClick={(e) => {                      
                      switch (question.questionTag) {
                        case 'security_welcome':
                          if (e.currentTarget.value === 'yes') {
                            setShowSecurityWelome(true);
                          } else {
                            setShowSecurityWelome(false);
                          }
                          break;
                        case 'vibration_machine':
                          if (e.currentTarget.value === 'yes') {
                            setShowVibrationMachine(true);
                          } else {
                            setShowVibrationMachine(false);
                          }
                          break;
                        case 'accident_avoided':
                          if (e.currentTarget.value === 'yes') {
                            setShowAccidentAvoided(true);
                          } else {
                            setShowAccidentAvoided(false);
                          }
                          break;
                      }
                    }}
                  />
                )}
                 {(answer.type === 'checkbox') && (
                  <input 
                    id={`${question.questionTag} ${answer.value}`}
                    type={answer.type}
                    value={`${answer.value}`}
                    name={`${question.questionTag} ${answer.value}`}
                    defaultChecked={false}
                    onChange={(e) => {
                      switch (title) {
                        case '1. Supplay':
                          handleSupplayForm()
                          break;
                        case '2. Entreprise':
                          handleEntrepriseForm()
                          break;
                        case '3. Mission':
                          handleMissionForm()
                          break;
                        case '4. Bilan':
                          handleBilanForm()
                          break;
                      }
                      
                    }}
                    onClick={(e) => {                      
                      switch (question.questionTag) {
                        case 'security_welcome':
                          if (e.currentTarget.value === 'yes') {
                            setShowSecurityWelome(true);
                          } else {
                            setShowSecurityWelome(false);
                          }
                          break;
                        case 'vibration_machine':
                          if (e.currentTarget.value === 'yes') {
                            setShowVibrationMachine(true);
                          } else {
                            setShowVibrationMachine(false);
                          }
                          break;
                        case 'accident_avoided':
                          if (e.currentTarget.value === 'yes') {
                            setShowAccidentAvoided(true);
                          } else {
                            setShowAccidentAvoided(false);
                          }
                          break;
                      }
                    }}
                  />
                )}
                {answer.type === 'text' && (
                  <input
                    id={`${question.questionTag} ${answer.value}`}
                    type={answer.type}
                    value={protectionDetails}
                    name={`${question.questionTag}_details`}
                    style={answer.type === 'text' ? {
                      width: '100%',
                      border: '1px solid #323BA2',
                      color: '#323BA2',
                      borderRadius: '0.5rem',
                      padding: '1rem 0 1rem 4rem',
                      marginTop: '1rem',
                    } : {}
                    }
                    onChange={(e) => {
                      setProtectionDetails(e.target.value)                      
                    }}
                  />
                )}
              <label
                htmlFor={`${question.questionTag} ${answer.value}`}
                style={answer.type === 'text' ? {
                  position: 'absolute',
                  top: '1.9rem',
                  left: '1rem',
                } : {}
                }
              >
                {answer.label}
              </label>
            </div>
          ))}
        </div>
      )}

      {question.subAnswer && (
        <ul className={
          question.subAnswer.questionTag === 'security_welcome_details' && showSecurityWelome ? 'SupplayForm-subquestions' : 'SupplayForm-subquestions-hidden' ?
          question.subAnswer.questionTag === 'vibration_machine_details' && showVibrationMachine ? 'SupplayForm-subquestions' : 'SupplayForm-subquestions-hidden' ?
          question.subAnswer.questionTag === 'accident_avoided_details' && showAccidentAvoided ? 'SupplayForm-subquestions' : 'SupplayForm-subquestions-hidden' : 'SupplayForm-subquestions-hidden' : 'SupplayForm-subquestions-hidden'
        }
        >
          <li style={question.subAnswer.question === '' ? {listStyle: 'none'}: {}} className='SupplayForm-subquestions--question'>
            {question.subAnswer.question}
            <div className='SupplayForm-subquestions--subanswer'>
              {question.subAnswer.answers.map(answer => (
                <div
                  className='SupplayForm-subquestions--subanswer-choice'
                  style={
                    answer.type === 'text' && window.innerWidth < 900 ? {
                    width: '100%',
                    position: 'relative',
                  } :
                    answer.type === 'text' && window.innerWidth >= 900 ? {
                    width: '70%',
                    position: 'relative',
                  } :
                  {}
                  }
                >
                  {(answer.type === 'radio') && (
                    <input 
                      id={`${question.subAnswer.questionTag} ${answer.value}`}
                      type={answer.type}
                      value={`${answer.value}`}
                      name={question.subAnswer.questionTag}
                      checked={checkMissionDetails ? true : false}
                      onChange={(e) => {
                        switch (title) {
                          case '1. Supplay':
                            handleSupplayForm()
                            break;
                          case '2. Entreprise':
                            handleEntrepriseForm()
                            break;
                          case '3. Mission':
                            handleMissionForm()
                            break;
                          case '4. Bilan':
                            handleBilanForm()
                            break;
                        }
                      }}
                    />
                    )}
                    {(answer.type === 'checkbox') && (
                    <input 
                      id={`${question.subAnswer.questionTag} ${answer.value}`}
                      type={answer.type}
                      value={`${answer.value}`}
                      name={question.subAnswer.questionTag}
                      onChange={(e) => {
                        switch (title) {
                          case '1. Supplay':
                            handleSupplayForm()
                            break;
                          case '2. Entreprise':
                            handleEntrepriseForm()
                            break;
                          case '3. Mission':
                            handleMissionForm()
                            break;
                          case '4. Bilan':
                            handleBilanForm()
                            break;
                        }
                      }}
                    />
                    )}
                  {answer.type === 'text' && (
                      <input 
                      id={`${question.subAnswer.questionTag} ${answer.value}`}
                      type={answer.type}
                      value={accident_avoided_details}
                      name={question.subAnswer.questionTag}
                      style={{
                        width: '100%',
                        border: '1px solid #323BA2',
                        color: '#323BA2',
                        borderRadius: '0.5rem',
                        padding: '1rem 0 1rem 5rem',
                      }}
                      onChange={(e) => {
                        setAccident_avoided_details(e.target.value)
                        if (title === '4. Bilan') {
                          handleBilanForm()
                        }
                      }}
                    />
                  )}
                  <label
                    htmlFor={`${question.subAnswer.questionTag} ${answer.value}`}
                    style={answer.type === 'text' ? {
                      position: 'absolute',
                      top: '0.9rem',
                      left: '1rem',
                    } : {}
                    }
                    >
                      {answer.label}
                  </label>
                </div>
              ))}
            </div>
            </li>
        </ul>
      )}
    </li>
  );
}

export default QuestionComponent;
