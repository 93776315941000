import React from 'react';
import './styles.scss';
import Logo from '../../assets/images/horizontal.png';
import LoginForm from './Form/LoginForm';

function Login() {
  return (
    <div className="Login">
      <div className="Login-side--left">
        <h1 className="Login-side--left_title">
            <img className="Login-side--left_logo" src={Logo} alt="logo supplay" />
        </h1>
      </div>
      <div className="Login-side--right">
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
