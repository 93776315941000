import React, { createContext, useState } from "react";

interface FormationContextType {
    question: object;
    questions: Array<object>;
    // setFormationContext: React.Dispatch<React.SetStateAction<FormationContextType>>

}

export const FormationContext = createContext<FormationContextType | null>(null);
export const FormationProvider = props => {
    const [formationContext, setFormationContext] = useState<FormationContextType>({
        question: {},
        questions: [{}]
    });

    return (
        <FormationContext.Provider value={[formationContext, setFormationContext]}>
            {props.children}
        </FormationContext.Provider>
    )
}