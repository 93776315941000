import React, { useState } from 'react';
import axios from 'axios';
import './styles.scss';
import charcaters from '../../../assets/images/dummy&friend.png';
import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft, faChevronRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    
    const Auth = (e) => {
        e.preventDefault();
        axios.post(`https://${window.location.hostname}/api/login`, {
            email: email,
            password: password
        })
        .then ((response) => {
            if (response.status === 200) {
                localStorage.setItem('user', response.data.username);
                localStorage.setItem('userid', response.data.id);
            }
        })
        .catch ((err) => {
            if (err.status === 401) {
                throw new Error('Unauthorized');
            } else if (err.status === 404) {
                throw new Error('User not found');
            } else if (err.status === 500) {
                throw new Error('Internal server error');
            }            
        })
        .finally(() => {
            window.location.reload();
        })
    }

  return (
      <div className="LoginForm">
        <div className="LoginForm-images">
            <img className="LoginForm-image" src={charcaters} alt="Copine de Dummy et Dummy" />
        </div>
        {!forgotPassword && (
            <>
                <h2 className="LoginForm-welcome">
                    <Trans>Login.Welcome</Trans> !
                </h2>
                <form onSubmit={Auth} className="LoginForm-form">
                    <label className="LoginForm-form--label" htmlFor="email">E-mail</label>
                    <input 
                    className="LoginForm-form--input"
                    id='email' 
                    type="email" 
                    name="email" 
                    value={email} 
                    onChange={e => {
                        setEmail(e.target.value);
                    }}
                    />
                    <label className="LoginForm-form--label" htmlFor="password">
                        <Trans>Login.Password</Trans>
                    </label>
                    <input
                    className="LoginForm-form--input"
                    id='password' 
                    type={showPassword ? 'text' : 'password'} 
                    name="password" 
                    value={password}
                    onChange={e => {
                        setPassword(e.target.value);
                    }}
                    />
                    {!showPassword && (
                        <FontAwesomeIcon 
                            icon={faEyeSlash}
                            style={{
                            position: 'relative',
                            bottom: '2.5rem',
                            left: '18.7rem',
                            color: '#323BA2'
                            }}
                            onClick={() => {    
                                setShowPassword(!showPassword);
                            }} 
                        />
                    )}
                    {showPassword && (
                        <FontAwesomeIcon 
                            icon={faEye}
                            style={{
                                position: 'relative',
                                bottom: '2.5rem',
                                left: '18.8rem',
                                color: '#323BA2'
                            }}
                            onClick={() => {    
                                setShowPassword(!showPassword);
                            }} 
                        />
                    )}
                    <div className="LoginForm-form--buttons">
                        <button className="LoginForm-form--forgot-password" type="button" onClick={() => {
                            setForgotPassword(true);
                        }}>
                            <Trans>Login.Forgot</Trans> ?
                        </button>
                        <button className="LoginForm-form--submit" type="submit">
                            <Trans>Login.Login</Trans>
                            <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: '1rem'}} />
                        </button>
                    </div>
                </form>
            </>
        )}
        {forgotPassword && (
            <>
                <h2 className="LoginForm-welcome">
                    <Trans>Login.Forgot</Trans> !
                </h2>
                <form action="" className="LoginForm-welcome">
                    <label className="LoginForm-form--label" htmlFor="email" style={{marginBottom: '-0.5rem'}}>Saisis l'e-mail avec lequel tu as créé ton compte</label>
                    <input 
                        className="LoginForm-form--input"
                        type="email" 
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="LoginForm-form--buttons">
                        <button className="LoginForm-form--forgot-password" type="button" onClick={() => {
                            setForgotPassword(false);
                        }}>
                            <FontAwesomeIcon icon={faChevronLeft} style={{marginRight: '1rem'}} />
                            Ecran de connexion
                        </button>
                        <button className="LoginForm-form--submit" type="submit">
                            <FontAwesomeIcon icon={faCheck} style={{marginRight: '1rem'}} />
                            Valider
                        </button>
                    </div>
                </form>
            </>
        )}
    </div>
  );
}

export default LoginForm;
