import React from 'react';
import { Trans } from 'react-i18next';
import NavBar from './NavBar/NavBar';
import './styles.scss';

type HeaderProps = {
  username: string | null,
  toggleNavbar: () => boolean | void,
  activeNav: boolean,
  setActiveNav: React.Dispatch<React.SetStateAction<boolean>>,
  language: string,
  setLanguage: React.Dispatch<React.SetStateAction<string>>
}

function Header({ username, language, setLanguage, toggleNavbar, activeNav, setActiveNav }: HeaderProps) {

  return (
    <div className={!activeNav ? "Header" : "Header-blur"}>
      <header>
        <h1 className="Header-title">
          <Trans>Home.Hello</Trans> {username} !
        </h1>
        <nav>
          <NavBar language={language} setLanguage={setLanguage} username={username} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar} />
        </nav>
      </header>
    </div>
  );
}

export default Header;
