import React, { useEffect, useState } from 'react';
import { Route, Routes} from 'react-router';
import Login from '../Login/Login';
import Error from '../Error/Error';
import HomePage from '../HomePage/HomePage';
import './App.scss';
import FormationPage from '../Formations/FormationPage/FormationPage';
import FormationResults from '../Formations/FormationResults/FormationResults';
import QuestionaryPage from '../Questionaries/QuestionaryPage/QuestionaryPage';


function App() {
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [activeNav, setActiveNav] = useState(false);
  const [language, setLanguage] = useState('fr');

  const username = localStorage.getItem('user');

  const toggleNavbar: () => boolean | void = () => {
    setActiveNav(!activeNav);
  }

  useEffect(() => {
    if (username) {
      setIsUserLogged(true)
    }
  }, [username])

  return (
    <div className="App">
      
      {!isUserLogged && (
        <>
        {/* <Navigate to='/login' replace={true} /> */}
        <Routes>
          <Route path='/' element={<Login />} />
        </Routes>
        </>
      )}
      {isUserLogged && (
        <Routes>
          <Route path='*' element={<Error />} />
          {/* <Route path='/login' element={<Login setUsername={setUsername} />} /> */}
          <Route path='/' element={<HomePage username={username} language={language} setLanguage={setLanguage} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar} />} />
          <Route path='/question/:slug' element={<FormationPage username={username} language={language} setLanguage={setLanguage} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar} />} />
          <Route path='/formation-results' element={<FormationResults username={username} language={language} setLanguage={setLanguage} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar} />} />
          <Route path='/questionary/:slug' element={<QuestionaryPage username={username} language={language} setLanguage={setLanguage} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar} />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
