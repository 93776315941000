import React, { createContext, useState } from "react";

export const AnswerContext = createContext();
export const AnswerProvider = props => {
    const [answerContext, setAnswerContext] = useState();

    return (
        <AnswerContext.Provider value={[answerContext, setAnswerContext]}>
            {props.children}
        </AnswerContext.Provider>
    )
}


export const AnswerResponse = createContext();
export const AnswerResponseProvider = props => {
    const [answerResponse, setAnswerResponse] = useState();

    return (
        <AnswerResponse.Provider value={[answerResponse, setAnswerResponse]}>
            {props.children}
        </AnswerResponse.Provider>
    )
}