import React, { useEffect } from 'react';
import Header from '../../Partials/Header';
import './styles.scss';
import ProgressBar from '../QuestionaryComponents/ProgressBar';
import { NavLink, useLocation } from 'react-router-dom';
import SupplayForm from '../QuestionaryComponents/Form/SupplayForm';
import questionaryPng from '../../../assets/images/questionarySupplay.png';
import finishedQuestionary from '../../../assets/images/finishQuestionary.png';
import Modal from 'react-modal';

type QuestionaryProps = {
  username: string | null,
  language: string,
  setLanguage: React.Dispatch<React.SetStateAction<string>>,
  toggleNavbar: () => boolean | void,
  activeNav: boolean,
  setActiveNav: React.Dispatch<React.SetStateAction<boolean>>
}


function QuestionaryPage({ username, language, setLanguage, toggleNavbar, activeNav, setActiveNav }: QuestionaryProps) {

  const location = useLocation();
  const title = location.state.questionaryCategory;
  const [isSupplayFormComplete, setIsSupplayFormComplete] = React.useState<boolean>(false);
  const [isEntrepriseFormComplete, setIsEntrepriseFormComplete] = React.useState<boolean>(false);
  const [isMissionFormComplete, setIsMissionFormComplete] = React.useState<boolean>(false);
  const [isBilanFormComplete, setIsBilanFormComplete] = React.useState<boolean>(false);
  const [isFinalModalOpen, setIsFinalModalOpen] = React.useState<boolean>(false);

  const customStyles = {
    content: 
    window.innerWidth < 900 ? {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '0.5rem',
      padding: '0 0 1.5rem 0',
      maxWidth: '80%',
    } : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '1rem',
      padding: '0 0 1.5rem 0',
      maxWidth: '80%',
    }
  };

  useEffect(() => {
    isBilanFormComplete ? setIsFinalModalOpen(true) : setIsFinalModalOpen(false);
  }, [isBilanFormComplete])


  return (
    <div className="FormationPage">
        <Header language={language} setLanguage={setLanguage} username={username} activeNav={activeNav} setActiveNav={setActiveNav} toggleNavbar={toggleNavbar} />
        <main className={!activeNav ? 'HomePage-main' : 'HomePage-main--blur'} onClick={() => {
          if (activeNav && (window.innerWidth < 900)) {
            toggleNavbar();
          }
        }}
        >
          {isBilanFormComplete && (
            <Modal
              isOpen={isFinalModalOpen}
              style={customStyles}
            >
              <img src={finishedQuestionary} alt="supplay" style={{height: '70%', width: '100%'}}/>
              <div className='Questionary_modal'>
                <p className='Questionary_modal__text'>Nous avons bien reçu votre questionnaire, merci</p>
                  <NavLink to="/" className='Questionary_modal__button' onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth"
                    });
                  }}>
                    Retour à l'accueil
                  </NavLink>
              </div>
            </Modal>

          )}
          <div className="Questionary">
            <section className="Questionary-progress">
              <ProgressBar title={title} isSupplayFormComplete={isSupplayFormComplete} isEntrepriseFormComplete={isEntrepriseFormComplete} isMissionFormComplete={isMissionFormComplete} />
            </section>

            <section className={!activeNav ? "Questionary-questions" : "Questionary-questions--blur"}>
               {window.innerWidth > 900 && title === '1. Supplay' && (
                <img src={questionaryPng} alt="supplay" style={{position: 'absolute', left: '78%', marginTop: '2rem'}}/>
              )}
                <SupplayForm title={title} setIsSupplayFormComplete={setIsSupplayFormComplete} setIsEntrepriseFormComplete={setIsEntrepriseFormComplete} setIsMissionFormComplete={setIsMissionFormComplete} setIsBilanFormComplete={setIsBilanFormComplete} />

            </section>
          </div>


        </main>
    </div>
  );
}

export default QuestionaryPage;
