import React, { useEffect } from "react";
import './styles.scss';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import QuestionComponent from "./questionComponent";
import { useNavigate } from "react-router-dom";

function SupplayForm({title, setIsSupplayFormComplete, setIsEntrepriseFormComplete, setIsMissionFormComplete, setIsBilanFormComplete}) {
    

    const [questions, setQuestions] = React.useState(Array<any>);
    const [count, setCount] = React.useState(false);
    const [supplayResponse, setSupplayResponse] = React.useState(Array<any>);
    const [entrepriseResponse, setEntrepriseResponse] = React.useState({});
    const [missionResponse, setMissionResponse] = React.useState({});
    const [bilanResponse, setBilanResponse] = React.useState({});

    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
  }
    

    const handleFormSubmit = async(e) => {
        const form = e.target;
        const formdata = new FormData(form);
                
        const formJson = Object.fromEntries(formdata.entries());
        
        const response = await axios.post(`https://${window.location.hostname}/api/questionary`, {
            formTitle: title,
            body: formJson,
        })        

        if (response.status === 200) {
            if (title === '1. Supplay') {
                setIsSupplayFormComplete(true);
                setSupplayResponse(response.data.body);
                navigate('/questionary/entreprise', {state: {questionaryCategory: '2. Entreprise'}});
            } else if (title === '2. Entreprise') {
                setIsEntrepriseFormComplete(true);
                setEntrepriseResponse(response.data.body);
                navigate('/questionary/mission', {state: {questionaryCategory: '3. Mission'}});
            } else if (title === '3. Mission') {
                setIsMissionFormComplete(true);
                setMissionResponse(response.data.body);
                navigate('/questionary/bilan', {state: {questionaryCategory: '4. Bilan'}});
            } else if (title === '4. Bilan') {
                try {
                    setBilanResponse(response.data.body);
                    const form = {
                        supplay: supplayResponse,
                        entreprise: entrepriseResponse,
                        mission: missionResponse,
                        bilan: response.data.body,
                    }

                    await axios.post(`https://${window.location.hostname}/api/insertForm`, {
                        
                        body: {
                            response: form,
                            user: localStorage.getItem('userid')
                        }
                    })
                    setIsBilanFormComplete(true);
                    
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
        scrollToTop();

        if (title !== '4. Bilan') {
            const allInputs = document.querySelectorAll('input');
            allInputs.forEach(element => {
                element.checked = false;
            });
        }
    }

    useEffect(() => {

        if (title === '1. Supplay') {
            axios.get(`https://${window.location.hostname}/api/supplayQuestionary`)
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.log(error);
            })
        }
        if (title === '2. Entreprise') {
            axios.get(`https://${window.location.hostname}/api/entrepriseQuestionary`)
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.log(error);
            })
        }
        if (title === '3. Mission') {
            axios.get(`https://${window.location.hostname}/api/missionQuestionary`)
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.log(error);
            })
        }
        if (title === '4. Bilan') {
            axios.get(`https://${window.location.hostname}/api/bilanQuestionary`)
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.log(error);
            })
        }
    }, [title])

    
    return (
        <>
        <h2 className="Questionary-questions--title">
            {title}
        <div></div>
        </h2>
        <form className="SupplayForm" action="post" onSubmit={(e) => {
            e.preventDefault(); 
            handleFormSubmit(e);
        }}>
            <ul className="SupplayForm-block">
                {Array.isArray(questions) && questions?.map(question => {
                    return (
                        <QuestionComponent title={title} question={question} setCount={setCount} supplayResponse={supplayResponse} entrepriseResponse={entrepriseResponse} missionResponse={missionResponse} bilanResponse={bilanResponse} />    
                    )}
                )}
            </ul>
            
            {title !== '1. Supplay' && (
                <button
                    className={"SupplayForm-block--goback"}
                    type="button"
                    onClick={() => {
                        if (title === '2. Entreprise') {
                            navigate(`/questionary/supplay`, {state: {questionaryCategory: '1. Supplay'}})
                            scrollToTop();
                        } 
                        if (title === '3. Mission') {
                            navigate('/questionary/entreprise', {state: {questionaryCategory: '2. Entreprise'}})
                        } 
                        if (title === '4. Bilan') {
                            navigate('/questionary/mission', {state: {questionaryCategory: '3. Mission'}})
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} style={{marginLeft: '1rem'}}/>
                    Etape précédente
                </button>
            )}
            {title !== '4. Bilan' && (
            <button 
                className={"SupplayForm-block--submit"} 
                type="submit"
                disabled={count ? false : true}
            >
                Etape suivante
                <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: '1rem'}}/>    
            </button>   
            )}
            {title === '4. Bilan' && (
                <button 
                    className={"SupplayForm-block--submit"} 
                    type="submit"
                    disabled={count ? false : true}
                > 
                    Valider mon questionnaire
                    <FontAwesomeIcon icon={faCheck} style={{marginLeft: '1rem'}}/>
                </button>
            )}
        </form>
        </>
    )
}

export default SupplayForm;