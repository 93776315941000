import React from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

function Error() {

  let navigate = useNavigate();
  function goBack() {
    return navigate(-1);
  }

  return (
    <div className="Error">
      <h1>404</h1>
      <p>Oups ... ce n'était pas prévu !</p>
        <button className='Error-goback--button' onClick={goBack}>Revenir à la page précédente</button>
    </div>
  );
}

export default Error;
