import React from 'react';
import './styles.scss';
import Success from '../../../../assets/images/responseSuccess.png';
import Failed from '../../../../assets/images/responseFailed.png';

type QuestionProps = {
  activeNav: boolean,
  questions: any,
  question: any,
  isResponseSuccess: boolean

}
function Question({ activeNav, question, questions, isResponseSuccess }: QuestionProps) {  
  
  return (
    <div className="Question">
        <div className={!activeNav ? 'Question-welcome' : 'Question-welcome--blur'}>
          <h2 className="Question--title">
            {question && question.Formation.name}
          </h2>
          <p className="Question--number">
            {question && question.number} 
            <span className="Question--number-length">/ 
              {questions && questions.length} 
            </span>
          </p>
          <p className="Question--text">
            {question && question.intro_text_panel}
          </p>
          {isResponseSuccess === true && (
            <>
              <div className='Question-status'>
                <img className="Question-status--image" src={Success} alt="success" />
                <span style={{color: '#26A653'}}>Bien joué !</span>
              </div>
              <p className="Question-status--text" dangerouslySetInnerHTML={{ __html: `${question.final_text_panel.replace(/\n/g, '<br/>')}`}}></p>
            </>
          )}
          {isResponseSuccess === false && (
            <>
              <div className='Question-status'>
                <img className="Question-status--image" src={Failed} alt="failed" />
                <span style={{color: '#E70F47'}}>Mauvaise réponse !</span>
              </div>
              <p className="Question-status--text" dangerouslySetInnerHTML={{ __html: `${question.final_text_panel.replace(/\n/g, '<br/>')}`}}></p>
            </>
          )}
        </div>
    </div>
  );
}

export default Question;
