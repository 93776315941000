import React from 'react';
import avatar from '../../../../assets/images/avatar.png';
import frFlag from '../../../../assets/images/frFlag.png';
import enFlag from '../../../../assets/images/enFlag.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type UserLogsProps = {
  username: string | null,
  language: string,
  setLanguage: React.Dispatch<React.SetStateAction<string>>
}

function UserLogs({ username, language, setLanguage }: UserLogsProps) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();


  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  }
  

  return (
    <div className="UserLogs">
        <div className="UserLogs-block">
          <img className="UserLogs-block--img" src={avatar} alt="avatar profile" />
          <h3 className="UserLogs-block--user">
            {username}
            <FontAwesomeIcon icon={faChevronRight} />
          </h3>
        </div>

        <button onClick={logout} className="UserLogs-btn">
          <FontAwesomeIcon icon={faPowerOff} style={{marginRight: '0.5rem', height: '1rem'}}/>
          <Trans>Partials.Navbar.Logout</Trans>
        </button>

        <div className="UserLogs-language">
          <img src={language === 'fr' ? frFlag : enFlag} alt={language === 'fr' ? 'Drapeau Français' : 'English Flag'} style={{width: '30%', height: '100%'}} />
          {/* <span>{language}</span> */}
          <select className="UserLogs-language--select" onChange={
            (e) => {
              if (language !== e.target.value) {
                setLanguage(e.target.value);
              }
              i18n.changeLanguage(e.target.value);
              
            }
          }>
            {language === 'fr' && (
              <>
                <option value="fr">FR</option>
                <option value="en">EN</option>
              </>
            )}
            {language === 'en' && (
              <>
                <option value="en">EN</option>
                <option value="fr">FR</option>
              </>
            )}
          </select>
        </div>
    </div>
  );
}

export default UserLogs;
