import React from "react";
import { NavLink } from "react-router-dom";

function ProgressBar({isSupplayFormComplete, isEntrepriseFormComplete, isMissionFormComplete}) {
  const urlPath = window.location.pathname;

  
    return (
      <div className="Achievements-modal--questions" style={{backgroundColor: '#070C3A'}}>

        <div className="progress-container">
            <ul className="progress-steps">
              <NavLink to={`/questionary/supplay`} state={{questionaryCategory: '1. Supplay'}}>
                <li
                  className={isSupplayFormComplete ? "progress-items--complete" : "progress-items" ?
                  urlPath.split('/')[2] === 'supplay' ? "progress-items--active" : "progress-items" : "progress-items"}
                  >
                    Supplay
                  </li>
              </NavLink>
              {isSupplayFormComplete && (
                <NavLink to={`/questionary/entreprise`} state={{questionaryCategory: '2. Entreprise'}}>
                  <li
                    className={isEntrepriseFormComplete ? "progress-items--complete" : "progress-items" ?
                    urlPath.split('/')[2] === 'entreprise' ? "progress-items--active" : "progress-items" : "progress-items"}
                  >
                    Entreprise
                  </li>
                </NavLink>
              )}
              {!isSupplayFormComplete && (
                <li className="progress-items">Entreprise</li>
              )}
              {isEntrepriseFormComplete && (
                <NavLink to={`/questionary/mission`} state={{questionaryCategory: '3. Mission'}}>
                  <li
                    className={isMissionFormComplete ? "progress-items--complete" : "progress-items" ?
                    urlPath.split('/')[2] === 'mission' ? "progress-items--active" : "progress-items" : "progress-items"
                  }>
                  Mission
                  </li>
                </NavLink>
              )}
              {!isEntrepriseFormComplete && (
                <li className="progress-items">Mission</li>
              )}
              {isMissionFormComplete && (
                <NavLink to={`/questionary/bilan`} state={{questionaryCategory: '4. Bilan'}}>
                  <li className={urlPath.split('/')[2] === 'bilan' ? "progress-items--active" : "progress-items"}>Bilan</li>
                </NavLink>
              )}
              {!isMissionFormComplete && (
                <li className="progress-items">Bilan</li>
              )}
            </ul>
        </div>
      </div>
    )
} 

export default ProgressBar;