import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import axios from 'axios';
import { AnswerResponse } from '../../../../context/AnswerContext';
import { NavLink, useNavigate } from 'react-router-dom';


function Answer({ activeNav, questions, question, userFormation, setIsResponseSuccess, setImageAction, setInit_sequence, setLoader }) {  
  const navigate = useNavigate()
  
  const [goodAnswer, setGoodAnswer] = useState('');
  const [wrongAnswer, setWrongAnswer] = useState('');
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [answerResponse, setAnswerResponse] = useContext(AnswerResponse);
  const [isLastQuestion, setIslastQuestion] = useState(false);

  const [nextAvailable, setNextAvailable] = useState(false);

  useEffect(() => {
    const questionsLength = questions.length;
    if (question.number === questionsLength) {
      setIslastQuestion(true);
    }

  }, [questions, question, setInit_sequence])
  
  const dynamicLic = (answer) => {
    if (answer.goodAnswer) {
    return (
      <>
        {Array.isArray(question.Answers) && question.Answers.map(answer => (
          <li 
            key={answer.id}
            className="Answer-choices--list"  
          style={answer.goodAnswer ? {backgroundColor: '#26A653', color: '#fff'} : {backgroundColor: ''}}>
            <span className="Answer-choices--list-choice">{
              answer.choice === '01' ? 'A' :
              answer.choice === '02' ? 'B' :
              answer.choice === '03' ? 'C' :
              answer.choice === '04' ? 'D' :
              answer.choice === '05' ? 'E' :
              ''
            }. </span> {answer.answer}
          </li>
        ))}
      </>
    )}
    else {
      return (
        <>
        {Array.isArray(question.Answers) && question.Answers.map(answer => (
          <li 
            key={answer.id}
            className="Answer-choices--list"
          style={answer.id === answerResponse.id ? {backgroundColor: '#E70F47', color: 'white'} :  answer.goodAnswer ? {backgroundColor: '#26A653', color: '#fff'} : {backgroundColor: ''}}>
            <span className="Answer-choices--list-choice">{
                answer.choice === '01' ? 'A' :
                answer.choice === '02' ? 'B' :
                answer.choice === '03' ? 'C' :
                answer.choice === '04' ? 'D' :
                answer.choice === '05' ? 'E' :
                ''
            }. </span> {answer.answer}
          </li>
        ))}
      </>
      )
    }
  }


  const handleAnswer = async () => {
    const response = await axios.post(`https://${window.location.hostname}/api/answer/validate`, {
      answer: answerResponse.id,
      questionId: question.id,
      userFormationId: userFormation.id
    });
    if (response.data[0].status === 'success') {
      setIsResponseSuccess(true);
      dynamicLic(response.data[0]);
    } else {
      setIsResponseSuccess(false);
    }
    setGoodAnswer('green');
    setWrongAnswer('red');
    setImageAction(answerResponse.animation_array[0]);

    setTimeout(() => {
      setNextAvailable(true);
    }, 3000);

  }

  const handleInitSequence = (question, answer) => {
    if (question.init_sequence[0] !== "") {
      const split_init_sequence = question.init_sequence[0].split(',');      
      
      switch (answer.choice) {
        case "01":
          setInit_sequence(split_init_sequence[0].replace(' ', ''));
          break;
        case "02":
          setInit_sequence(split_init_sequence[1].replace(' ', ''));
          break;
        case "03":
          setInit_sequence(split_init_sequence[2].replace(' ', ''));
          break;
        default:
          console.log('No init images sequence loaded');
      }
    }

  }

  return (
    <div className={!activeNav ? 'Answer' : 'Answer--blur'}>
      <ul className='Answer-choices'>
        {Array.isArray(question.Answers) && !goodAnswer && !wrongAnswer && question.Answers.map(answer => (
          <li 
            key={answer.id}
            className="Answer-choices--list"
            onClick={(e) => {
              if (!isAnswerSelected) {
                e.currentTarget.classList.add("Answer-choices--list-seclected");
                setIsAnswerSelected(!isAnswerSelected);
                setAnswerResponse(answer);
              } else if (isAnswerSelected && e.currentTarget.classList.contains('Answer-choices--list-seclected')) {
                e.currentTarget.classList.remove("Answer-choices--list-seclected");
                setIsAnswerSelected(!isAnswerSelected);
                setAnswerResponse('');
              }
            }}
            onTouchStartCapture={() => {
              handleInitSequence(question, answer);
            }}
            onTouchEndCapture={() => setInit_sequence('')}
            onMouseEnter={() => {
              handleInitSequence(question, answer);
            }}
            onMouseLeave={() => setInit_sequence('')}
          >
            <span className="Answer-choices--list-choice">{
              answer.choice === '01' ? 'A' :
              answer.choice === '02' ? 'B' :
              answer.choice === '03' ? 'C' :
              answer.choice === '04' ? 'D' :
              answer.choice === '05' ? 'E' :
              ''
            }. </span> {answer.answer}
          </li>
        ))}
        {goodAnswer && wrongAnswer && (dynamicLic(answerResponse))}
      </ul>
      {!goodAnswer && !wrongAnswer && isAnswerSelected && (
        <button className="Answer-choices--validate" onClick={handleAnswer}>
          <FontAwesomeIcon icon={faCheck} style={{marginRight: '1rem'}} /> 
          Valider ma réponse
        </button>
      )}
      {!isAnswerSelected && (
        <button className="Answer-choices--validate-disabled">
          <FontAwesomeIcon icon={faCheck} style={{marginRight: '1rem'}} /> 
          Valider ma réponse
        </button>
      )}
      {goodAnswer && wrongAnswer && !isLastQuestion && nextAvailable && (
        
        <button className="Answer-choices--validate" 
        onClick={() => {
          setNextAvailable(false);
          setImageAction('');
          setLoader(true);
          axios.get(`https://${window.location.hostname}/api/question/${question.id + 1}`)
          .then(response => {

            setGoodAnswer('');
            setWrongAnswer('');
            setIsAnswerSelected(false);
            navigate(`/question/${response.data.id}`, {
              state: {
                question: response.data,
                formation: userFormation,
                questions: questions
              }

            })
            setTimeout(() => {
              setLoader(false);
            }, 500);
          })
          .catch(error => {
            throw(error.status)
          })
          setIsResponseSuccess()
        }}
        >
          Question suivante
          <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: '1rem'}} />
        </button>
      )}
      {goodAnswer && wrongAnswer && isLastQuestion && (
        <NavLink to='/formation-results' state={{formation: userFormation, questions: questions}}>
          <button className="Answer-choices--validate">
            Ouvrir les résultats
          </button>
        </NavLink>
      )}
    </div>
  );
}

export default Answer;
